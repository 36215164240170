import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { fetchProjects, updateProject, setUser, toggleChatWindow } from './projectActions';
import Navbar from './Navbar.js';
import AIChatWindow from './AIChatWindow';
import './ProjectDetails.css';

function adjustHeight(element) {
  element.style.height = 'auto';
  element.style.height = element.scrollHeight + 'px';
}

const adjustAllTextareas = () => {
  const textareas = document.querySelectorAll('.project-details-expanded textarea');
  textareas.forEach(textarea => adjustHeight(textarea));
  const textareascollapsed = document.querySelectorAll('.project-details-collapsed textarea');
  textareas.forEach(textareascollapsed => adjustHeight(textareascollapsed));

};

const ProjectDetails = () => {
  const mainContentRef = useRef(null);
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const projects = useSelector(state => state.project.projects);
  const [projectData, setProjectData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const isAIChatCollapsed = useSelector(state => state.project.isAIChatCollapsed);
  const projectDetailsClass = isAIChatCollapsed ? 'project-details-collapsed' : 'project-details-expanded';


  useEffect(() => {
    if (Array.isArray(projects) && projectId) {
      const currentProject = projects.find(proj => proj.project_id === projectId);
      if (currentProject) {
        setProjectData(currentProject);
        setIsLoading(false);
        adjustAllTextareas();
      }
    }
  }, [projects, projectId]);
  

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        if (userData && userData.attributes && userData.attributes.name != null) {
          dispatch(setUser(userData.attributes));
        }
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        dispatch(fetchProjects(token));
      } catch (error) {
        console.error('Error fetching user', error);
      }
    };
    fetchUser();
  }, [dispatch]);

  const handleSave = () => {
    dispatch(updateProject(projectId, projectData));
  };

  const handleToggleChatWindow = () => {
    dispatch(toggleChatWindow());
  };

  return (
    <div>
          <Navbar />
      <div className={projectDetailsClass}>
      
        <h2>Project Details</h2>
        {isLoading ? (
          <div className="loading">
            <span className="spinner"></span>
            Loading...
          </div>
        ) : projectData ? (

        <div>
          <label>
            Title
            <input type="text" value={projectData.title} onChange={(e) => setProjectData({ ...projectData, title: e.target.value })} />
          </label>
          <label>
            Summary
            <textarea value={projectData.summary} onChange={(e) => { setProjectData({ ...projectData, summary: e.target.value }); adjustHeight(e.target); }}></textarea>
          </label>
          <label>
            Genre
            <input type="text" value={projectData.genre} onChange={(e) => setProjectData({ ...projectData, genre: e.target.value })} />
          </label>
          <label>
            Subgenre
            <input type="text" value={projectData.subgenre} onChange={(e) => setProjectData({ ...projectData, subgenre: e.target.value })} />
          </label>
          <label>
            Word Count Target
            <input type="number" value={projectData.word_count_target} onChange={(e) => setProjectData({ ...projectData, word_count_target: e.target.value })} />
          </label>
          <label>
            Setting
            <textarea value={projectData.setting} onChange={(e) => { setProjectData({ ...projectData, setting: e.target.value }); adjustHeight(e.target); }}></textarea>
          </label>
          <label>
            Theme
            <textarea value={projectData.theme} onChange={(e) => { setProjectData({ ...projectData, theme: e.target.value }); adjustHeight(e.target); }}></textarea>
          </label>
          <label>
            Point of View
            <input type="text" value={projectData.point_of_view} onChange={(e) => setProjectData({ ...projectData, point_of_view: e.target.value })} />
          </label>
          <label>
            Conflict
            <textarea value={projectData.conflict} onChange={(e) => { setProjectData({ ...projectData, conflict: e.target.value }); adjustHeight(e.target); }}></textarea>
          </label>
          <label>
            Resolution
            <textarea value={projectData.resolution} onChange={(e) => { setProjectData({ ...projectData, resolution: e.target.value }); adjustHeight(e.target); }}></textarea>
          </label>
          <label>
            Tone
            <input type="text" value={projectData.tone} onChange={(e) => setProjectData({ ...projectData, tone: e.target.value })} />
          </label>
          <label>
            Style
            <textarea value={projectData.style} onChange={(e) => { setProjectData({ ...projectData, style: e.target.value }); adjustHeight(e.target); }}></textarea>
          </label>
          <label>
            Intended Audience
            <input type="text" value={projectData.intended_audience} onChange={(e) => setProjectData({ ...projectData, intended_audience: e.target.value })} />
          </label>
          <label>
            Additional Notes
            <textarea value={projectData.additional_notes} onChange={(e) => { setProjectData({ ...projectData, additional_notes: e.target.value }); adjustHeight(e.target); }}></textarea>
          </label>
          <button className="save-button" onClick={handleSave} title="Save the project details">Save <i className="save-icon"></i></button>
        </div>

      ) : (
        <p>Project not found</p>
      )}

    </div>
    <AIChatWindow isCollapsed={isAIChatCollapsed} toggleCollapse={handleToggleChatWindow} projectData={projectData} />

    </div>
    );
};

export default ProjectDetails;
