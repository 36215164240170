import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import LandingPage from './LandingPage';
import Welcome from './Welcome';
import './App.css';
import Navbar from "./Navbar";
import ProjectDetails from './ProjectDetails';
import ProjectCreation from './ProjectCreation';
import Characters from './Characters';
import CreateCharacter from './CreateCharacter';
import CharacterDetails from './CharacterDetails';
import ChapterList from './ChapterList';

const Dashboard = () => {
  useEffect(() => {
    document.title = 'FableFlow';
  });
  
  const dispatch = useDispatch();
  const handleLogout = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div>
      <Navbar />
      <Welcome />
    </div>
  );

};


const AuthenticatedApp = withAuthenticator(Dashboard);

const App = () => {
  return (
    <Router>
      <Routes>
        
        <Route path="/" element={<LandingPage />} />
        <Route path="/app" element={<AuthenticatedApp />} />
        <Route path="/project/:projectId" element={<ProjectDetails />} />
        <Route path="/create-project" element={<ProjectCreation />} />
        <Route path="/project/:projectId/characters" element={<Characters />} />
        <Route path="/project/:projectId/characters/create" element={<CreateCharacter />} />
        <Route path="/project/:projectId/characters/:characterId" element={<CharacterDetails />} />   
        <Route path="/project/:projectId/chapters" element={<ChapterList />} />
      </Routes>
    </Router>
  );
};

export default App;
