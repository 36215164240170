import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createCharacter, fetchProjects } from './projectActions';
import Navbar from './Navbar.js';
import AIChatWindow from './AIChatWindow';
import './Characters.css'; // Assuming you have a separate CSS file for this component
import { Auth } from 'aws-amplify';

const CreateCharacter = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [portraitUrl, setPortraitUrl] = useState(null);

    // Use Redux selectors to access state
    const projects = useSelector(state => state.project.projects);

    const [character, setCharacter] = useState({
        name: '',
        age: '',
        gender: 'Male',
        description: '',
        traits: [],
        backstory: ''
    });
    const [currentTrait, setCurrentTrait] = useState('');

    // Fetch user details and projects on component mount
    useEffect(() => {
        dispatch(fetchProjects());
    }, [dispatch]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCharacter(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const addTrait = () => {
        setCharacter(prevState => ({
            ...prevState,
            traits: [...prevState.traits, currentTrait]
        }));
        setCurrentTrait('');
    };

    const removeTrait = index => {
        setCharacter(prevState => ({
            ...prevState,
            traits: prevState.traits.filter((_, i) => i !== index)
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        dispatch(createCharacter(projectId, character));
        navigate(`/project/${projectId}/characters`);
    };
    
    const generatePortrait = async () => {
        try {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            const imageText = "Generate a hand-drawn portrait of the following book character:" + character.description
            const response = await fetch('https://lqnj29xyg8.execute-api.us-west-2.amazonaws.com/v1/generate_image', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ text: imageText, size: "1024x1024" }),
            });

            if (response.ok) {
                const data = await response.json();
                setPortraitUrl(data.image_url);
            } else {
                console.error('Error generating portrait:', await response.text());
            }
        } catch (error) {
            console.error('Unexpected error while generating portrait:', error);
        }
    };

    return (
        <div>
        <Navbar />
        <div className="create-character-container">
           
            <h2>Create New Character</h2>
            <form onSubmit={handleSubmit}>
                <div className="input-wrapper">
                    <label>Name:</label>
                    <input type="text" name="name" value={character.name} onChange={handleInputChange} required />
                </div>
                <div className="input-wrapper">
                    <label>Age:</label>
                    <input type="text" name="age" value={character.age} onChange={handleInputChange} required />
                </div>
                <div className="input-wrapper">
                    <label>Gender:</label>
                    <select name="gender" value={character.gender} onChange={handleInputChange} required>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="input-wrapper">
                    <label>Description:</label>
                    <textarea name="description" value={character.description} onChange={handleInputChange} required />
                    <button type="button" onClick={generatePortrait}>Generate Portrait</button>
                </div>

                {portraitUrl && (
                    <div className="portrait-container">
                        <img src={portraitUrl} alt="Generated Portrait" />
                    </div>
                )}
                
                <div className="input-wrapper">
                    <label htmlFor="traits">Traits</label>
                    <input type="text" id="traits" value={currentTrait} onChange={(e) => setCurrentTrait(e.target.value)} placeholder="Enter a trait and press Add" />
                    <button type="button" onClick={addTrait}>Add Trait</button>
                </div>
                <ul>
                    {character.traits.map((trait, index) => (
                        <li key={index}>{trait} <button type="button" onClick={() => removeTrait(index)}>Remove</button></li>
                    ))}
                </ul>
                <div className="input-wrapper">
                    <label>Backstory:</label>
                    <textarea name="backstory" value={character.backstory} onChange={handleInputChange} required />
                </div>
                <button type="submit" className="create-character-button">Create Character</button>
            </form>
            
        </div>
        <AIChatWindow />
        </div>
    );
};

export default CreateCharacter;
