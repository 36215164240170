// projectReducer.js

import {
    FETCH_CHAPTERS_BEGIN,
    FETCH_CHAPTERS_SUCCESS,
    FETCH_CHAPTERS_FAILURE,
    FETCH_CHARACTERS_BEGIN,
    FETCH_CHARACTERS_SUCCESS,
    FETCH_CHARACTERS_FAILURE,
    FETCH_PLOTS_BEGIN,
    FETCH_PLOTS_SUCCESS,
    FETCH_PLOTS_FAILURE,
    FETCH_PROJECTS_BEGIN,
    FETCH_PROJECTS_SUCCESS,
    FETCH_PROJECTS_FAILURE,
    ADD_CHAPTER_SUCCESS,
    UPDATE_CHAPTER_SUCCESS,
    DELETE_CHAPTER_SUCCESS,
    REORDER_CHAPTERS_SUCCESS,
    FETCH_CHAT_HISTORY_SUCCESS,
    STORE_MESSAGE_SUCCESS,
    SET_SELECTED_VOICE,
    TOGGLE_MUTE,
    SET_USER,
    SET_PROJECTS,
    TOGGLE_CHAT_WINDOW,
    CREATE_CHARACTER_REQUEST,
    CREATE_CHARACTER_SUCCESS,
    CREATE_CHARACTER_FAILURE,
    CREATE_PROJECT_REQUEST,
    CREATE_PROJECT_SUCCESS,
    CREATE_PROJECT_FAILURE
  } from './projectActions';
  
  const initialState = {
    projects: [],
    chapters: [],
    characters: [],
    plots: [],
    messages: [],
    loading: false,
    error: null,
    chapterUpdated: false,
    project: {
      project_id: null,
    },
    isMuted: false,
    selectedVoice: 'Matthew',
    isAIChatCollapsed: false,
  };
  
  const projectReducer = (state = initialState, action) => {
    switch (action.type) {
      case TOGGLE_CHAT_WINDOW:
      return {
        ...state,
        isAIChatCollapsed: !state.isAIChatCollapsed,
      };
      case FETCH_PROJECTS_BEGIN:
      case FETCH_CHAPTERS_BEGIN:
      case FETCH_CHARACTERS_BEGIN:
      case FETCH_PLOTS_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
  
      case ADD_CHAPTER_SUCCESS:
      case UPDATE_CHAPTER_SUCCESS:
      case DELETE_CHAPTER_SUCCESS:
      case REORDER_CHAPTERS_SUCCESS:
        return {
          ...state,
          chapters: action.payload.chapters,
          chapterUpdated: true
        };

      case FETCH_CHAPTERS_SUCCESS:
        return {
          ...state,
          loading: false,
          chapters: action.payload.chapters,
          chapterUpdated: false
        };

      case FETCH_PROJECTS_SUCCESS:
        return {
          ...state,
          loading: false,
          projects: action.payload.projects
        };
  
      case FETCH_CHAPTERS_SUCCESS:
        return {
          ...state,
          loading: false,
          chapters: action.payload.chapters
        };
  
        case FETCH_CHARACTERS_SUCCESS:
            const newState = {
                ...state,
                characters: action.payload.characters,
            };
            
            return newState;
        
  
      case FETCH_PLOTS_SUCCESS:
        return {
          ...state,
          loading: false,
          plots: action.payload.plots
        };
  
      case FETCH_CHAT_HISTORY_SUCCESS:
        return {
          ...state,
          messages: action.payload.messages,
        };

        case STORE_MESSAGE_SUCCESS:
          return {
            ...state,
            messages: { ...state.messages, messages: [...state.messages.messages, action.payload.message] },
          };
        
      case FETCH_PROJECTS_FAILURE:
      case FETCH_CHAPTERS_FAILURE:
      case FETCH_CHARACTERS_FAILURE:
      case FETCH_PLOTS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          projects: [],
          chapters: [],
          characters: [],
          plots: []
        };
        case SET_SELECTED_VOICE:
          return {
            ...state,
            selectedVoice: action.payload.voiceId,
          };
        case TOGGLE_MUTE:
          return {
            ...state,
            isMuted: !state.isMuted,
          };
          case SET_USER:
            return {
              ...state,
              user: JSON.stringify(action.payload), // Serialize the user object to JSON
            };

            case SET_PROJECTS:
              return {
                ...state,
                projects: action.payload,
              };

              case CREATE_CHARACTER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CREATE_CHARACTER_SUCCESS:
            return {
                ...state,
                characters: [...state.characters, action.payload],
                loading: false
            };
        case CREATE_CHARACTER_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case CREATE_PROJECT_REQUEST:
              return {
                  ...state,
                  loading: true
              };
        case CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                projects: [...state.projects, action.payload],
                loading: false
            };
        case CREATE_PROJECT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };
      default:
        return state;
    }
  };

  export default projectReducer;  