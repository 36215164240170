import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import './Characters.css';
import Navbar from './Navbar.js';
import AIChatWindow from './AIChatWindow';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setProjectDetails } from './projectSlice';
import { setProjects, selectProjects } from './projectSlice';
import { selectUser, setUser } from './userSlice';

const Characters = () => {
    const { projectId } = useParams();
    const [characters, setCharacters] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isMuted, setIsMuted] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedVoice, setSelectedVoice] = useState('Matthew');
    const projects = useSelector(selectProjects);
    const [projectData, setProjectData] = useState({});

    const navigateToCreateCharacter = () => {
        navigate(`/project/${projectId}/characters/create`);
    };

    const navigateToCharacterDetail = (characterId) => {
        navigate(`/project/${projectId}/characters/${characterId}`);
    };

    const deleteCharacter = async (character) => {
        const userConfirmed = window.confirm(`Are you sure you want to delete ${character.name}?`);

        if (!userConfirmed) {
            return;
          }
      
          try {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            const characterId = character['item_type#item_id'].split('#')[1];
            const response = await fetch(`https://kto6bliwo3.execute-api.us-west-2.amazonaws.com/v1/characters/project/${projectId}/${characterId}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            });
      
            if (response.ok) {
              dispatch(fetchProjects(token));
            } else {
              console.error('Error deleting project', response.status);
            }
          } catch (error) {
            console.error('Error deleting project', error);
          }
    };

    const fetchProjects = async (token) => {
        try {
            const response = await fetch('https://bhg6huunyl.execute-api.us-west-2.amazonaws.com/v1/projects/my/', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                dispatch(setProjects(data));
            } else {
                console.error('Error fetching projects', response.status);
            }
        } catch (error) {
            console.error('Error fetching projects', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await Auth.currentAuthenticatedUser();
                if (userData && userData.attributes && userData.attributes.name != null) {
                    dispatch(setUser(userData.attributes));
                }
                const session = await Auth.currentSession();
                const token = session.getIdToken().getJwtToken();
                fetchProjects(token);
            } catch (error) {
                console.error('Error fetching user', error);
            }
        };
      
        fetchUser();
    }, [dispatch]);

    const projectDetails = projects.find((proj) => proj.project_id === projectId);
    useEffect(() => {
        if (projectDetails) {
            setProjectData(projectDetails);
        }
    }, [projectDetails]);

    useEffect(() => {
        const fetchCharacters = async () => {
            try {
                const session = await Auth.currentSession();
                const token = session.getIdToken().getJwtToken();
                const response = await fetch(`https://kto6bliwo3.execute-api.us-west-2.amazonaws.com/v1/characters/project/${projectId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setCharacters(data);
                } else {
                    console.error('Error fetching characters', response.status);
                }
            } catch (error) {
                console.error('Error fetching characters', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCharacters();
    }, [projectId]);

    const toggleMute = () => {
        setIsMuted((prevMuted) => !prevMuted);
    };

     return (
        <div className="characters-container">
            <Navbar isMuted={isMuted} toggleMute={toggleMute} selectedVoice={selectedVoice} setSelectedVoice={setSelectedVoice} projects={projects} currentProjectId={projectId} />
            <h2>Characters</h2>
            {isLoading ? (
                <div className="loading">
                    <span className="spinner"></span>
                    Loading...
                </div>
            ) : (
                <>
                <ul className="character-list">
                    {characters.map(character => (
                        <li key={character['item_type#item_id']} className="character-item">
                            <button className="delete-btn" onClick={() => deleteCharacter(character)}>X</button>
                            <h3 onClick={() => navigateToCharacterDetail(character['item_type#item_id'].split('#')[1])}>{character.name}</h3>
                            <p><strong>Age:</strong> {character.age}</p>
                            <p><strong>Gender:</strong> {character.gender}</p>
                            <p><strong>Description:</strong> {character.description}</p>
                            <p><strong>Traits:</strong> {character.traits.join(', ')}</p>
                            <p><strong>Backstory:</strong> {character.backstory}</p>
                        </li>
                    ))}
                </ul>

                <button onClick={navigateToCreateCharacter} className="create-character-button">
                    Create Character
                </button>
                </>
            )}
            
            <br />
            <br />
            <AIChatWindow isMuted={isMuted} projectData={projectDetails} messages={chatHistory} setChatHistory={setChatHistory} selectedVoice={selectedVoice} />
        </div>
    );
}

export default Characters;