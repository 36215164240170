import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import Navbar from './Navbar.js';
import AIChatWindow from './AIChatWindow';
import './Welcome.css';

import { VoiceProvider } from './VoiceContext';
import { fetchProjects, setUser, setProjects, deleteProject as deleteProjectAction } from './projectActions';

const Welcome = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projects = useSelector(state => state.project.projects); 
  const [isLoading, setIsLoading] = useState(true); 
  const [isMuted, setIsMuted] = useState(false); // Define local state for mute

  const handleProjectClick = (project) => {
    navigate(`/project/${project.project_id}`);
  };

  const handleCreateProjectClick = () => {
    navigate('/create-project');
  };

  useEffect(() => {
    const fetchUserAndProjects = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        if (userData && userData.attributes && userData.attributes.name != null) {
          dispatch(setUser(userData.attributes));
        }
      } catch (error) {
        console.error('Error fetching user', error);
      }

      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        dispatch(fetchProjects(token)); // Dispatch fetchProjects action
      } catch (error) {
        console.error('Error fetching projects', error);
      }
    };

    fetchUserAndProjects();
  }, [dispatch]);

  const fetchProjects = (token) => {
    return async (dispatch) => {
      try {
        const response = await fetch('https://bhg6huunyl.execute-api.us-west-2.amazonaws.com/v1/projects/my/', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          dispatch(setProjects(data));
        } else {
          console.error('Error fetching projects', response.status);
        }
      } catch (error) {
        console.error('Error fetching projects', error);
      } finally {
        setIsLoading(false);  // Set isLoading to false once the API call is complete
      }
    };
  };

  const deleteProject = async (projectId) => {
    const userConfirmed = window.confirm("Are you sure you want to delete this project?");
    if (!userConfirmed) {
      return;
    }

    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();

      const response = await fetch(`https://bhg6huunyl.execute-api.us-west-2.amazonaws.com/v1/projects/${projectId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        dispatch(fetchProjects(token));
      } else {
        console.error('Error deleting project', response.status);
      }
    } catch (error) {
      console.error('Error deleting project', error);
    }
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const project = projects.find((project) => project.project_id === projectId);

  return (
    <div className="welcome">
      <h1>Welcome to FableFlow!</h1>
      
      {isLoading ? (
      <div className="loading">Loading...</div> 
      
    ) : projects && projects.length > 0 ? (
      <div className="project-list">
          {projects.map((project) => (
            <div className="project-card" key={project.project_id}>
              <button className="delete-btn" onClick={() => deleteProject(project.project_id)}>X</button>
              <h2 onClick={() => handleProjectClick(project)}>{project.title}</h2>
              <p>{project.summary}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="cta">
          <h2>You don't have any projects yet.</h2>
          <p>Start bringing your ideas to life!</p>
        </div>
      )}
      <button className="create-project-btn" onClick={handleCreateProjectClick}>
        {projects && projects.length > 0 ? 'Create New Project' : 'Start Your First Project'}
      </button>
    </div>
  );
}

export default Welcome;
