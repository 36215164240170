import { Auth } from 'aws-amplify';

const getAuthToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

const PROJECTS_API_BASE_URL=process.env.REACT_APP_FABLEFLOW_PROJECTS_API_BASE_URL;
const CHARACTERS_API_BASE_URL=process.env.REACT_APP_FABLEFLOW_CHARACTERS_BASE_URL;
const PLOTS_API_BASE_URL=process.env.REACT_APP_FABLEFLOW_PLOTS_BASE_URL;
const CHAPTERS_API_BASE_URL=process.env.REACT_APP_FABLEFLOW_CHAPTERS_BASE_URL;
const CHAT_API_BASE_URL=process.env.REACT_APP_FABLEFLOW_CHAT_BASE_URL;
const API_PROJECTS_URL = `${PROJECTS_API_BASE_URL}/projects`;
const API_PLOTS_URL = `${PLOTS_API_BASE_URL}/plots`;
const API_CHAPTERS_URL = `${CHAPTERS_API_BASE_URL}/chapters`;
const API_CHARACTERS_URL = `${CHARACTERS_API_BASE_URL}/characters`;
const API_CHAT_HISTORY_URL = `${CHAT_API_BASE_URL}/chat-history`;

function generateUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

// Action Types
export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';

export const FETCH_CHAPTERS_BEGIN = 'FETCH_CHAPTERS_BEGIN';
export const FETCH_CHAPTERS_SUCCESS = 'FETCH_CHAPTERS_SUCCESS';
export const FETCH_CHAPTERS_FAILURE = 'FETCH_CHAPTERS_FAILURE';

export const FETCH_CHARACTERS_BEGIN = 'FETCH_CHARACTERS_BEGIN';
export const FETCH_CHARACTERS_SUCCESS = 'FETCH_CHARACTERS_SUCCESS';
export const FETCH_CHARACTERS_FAILURE = 'FETCH_CHARACTERS_FAILURE';

export const CREATE_CHARACTER_REQUEST = 'CREATE_CHARACTER_REQUEST';
export const CREATE_CHARACTER_SUCCESS = 'CREATE_CHARACTER_SUCCESS';
export const CREATE_CHARACTER_FAILURE = 'CREATE_CHARACTER_FAILURE';

export const FETCH_PLOTS_BEGIN = 'FETCH_PLOTS_BEGIN';
export const FETCH_PLOTS_SUCCESS = 'FETCH_PLOTS_SUCCESS';
export const FETCH_PLOTS_FAILURE = 'FETCH_PLOTS_FAILURE';

export const FETCH_PROJECTS_BEGIN = 'FETCH_PROJECTS_BEGIN';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

export const ADD_CHAPTER_SUCCESS = 'ADD_CHAPTER_SUCCESS';
export const UPDATE_CHAPTER_SUCCESS = 'UPDATE_CHAPTER_SUCCESS';
export const DELETE_CHAPTER_SUCCESS = 'DELETE_CHAPTER_SUCCESS';
export const REORDER_CHAPTERS_SUCCESS = 'REORDER_CHAPTERS_SUCCESS';

export const FETCH_CHAT_HISTORY_BEGIN = 'FETCH_CHAT_HISTORY_BEGIN';
export const FETCH_CHAT_HISTORY_SUCCESS = 'FETCH_CHAT_HISTORY_SUCCESS';
export const FETCH_CHAT_HISTORY_FAILURE = 'FETCH_CHAT_HISTORY_FAILURE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const STORE_MESSAGE_SUCCESS = 'STORE_MESSAGE_SUCCESS';

export const SET_SELECTED_VOICE = 'SET_SELECTED_VOICE';
export const TOGGLE_MUTE = 'TOGGLE_MUTE';
export const SET_USER = 'SET_USER';
export const SET_PROJECTS = 'SET_PROJECTS';

export const TOGGLE_CHAT_WINDOW = 'TOGGLE_CHAT_WINDOW';

// Action creators
export const toggleChatWindow = () => {
  console.log("toggleChatWindow action dispatched");
  return {
    type: TOGGLE_CHAT_WINDOW,
  };
};


export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const setProjects = (projects) => ({
  type: SET_PROJECTS,
  payload: projects,
});

const fetchEntityBegin = (type) => ({
  type: type
});

const fetchEntitySuccess = (type, payload) => ({
  type: type,
  payload: payload
});

const fetchEntityFailure = (type, error) => ({
  type: type,
  payload: { error }
});

const addEntitySuccess = (type, entity) => ({
  type: type,
  payload: { entity }
});

const updateEntitySuccess = (type, entityId, updates) => ({
  type: type,
  payload: { entityId, updates }
});

const deleteEntitySuccess = (type, entityId) => ({
  type: type,
  payload: { entityId }
});

export const setChapterUpdated = (updated) => ({
  type: 'SET_CHAPTER_UPDATED',
  payload: updated
});

// Thunk Action Creators for CRUD operations
export const addChapter = (projectId, chapterData) => {
    return async (dispatch, getState) => {
        const token = await getAuthToken();
        const chapters = getState().chapters || []; 

        const newChapterData = {
            ...chapterData
        };

        try {
            const response = await fetch(`${API_CHAPTERS_URL}/project/${projectId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(newChapterData)
            });

            if (response.ok) {
                const newChapter = await response.json();
                dispatch(addEntitySuccess(ADD_CHAPTER_SUCCESS, newChapter));
            } else {
                throw new Error('Error adding chapter');
            }
        } catch (error) {
            console.error('Error adding chapter', error);
        }

        
        dispatch(setChapterUpdated(true));
    };
};

export const updateChapter = (projectId, chapterData) => {
  return async (dispatch) => {
    const chapterId = chapterData['item_type#item_id'].split('#')[1]
    const token = await getAuthToken();
    try {
      const response = await fetch(`${API_CHAPTERS_URL}/project/${projectId}/${chapterId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(chapterData)
      });

      if (response.ok) {
        const updatedChapter = await response.json();
        dispatch(updateEntitySuccess(UPDATE_CHAPTER_SUCCESS, chapterId, updatedChapter));
      } else {
        throw new Error('Error updating chapter');
      }
    } catch (error) {
      console.error('Error updating chapter', error);
    }
    
    dispatch(setChapterUpdated(true));
  };
};

export const deleteChapter = (projectId, chapterId) => {
    chapterId = chapterId.toString();
    chapterId = chapterId.split('#')[1]
  return async (dispatch) => {
    const token = await getAuthToken();
    try {
      const response = await fetch(`${API_CHAPTERS_URL}/project/${projectId}/${chapterId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.ok) {
        dispatch(deleteEntitySuccess(DELETE_CHAPTER_SUCCESS, chapterId));
      } else {
        throw new Error('Error deleting chapter');
      }
    } catch (error) {
      console.error('Error deleting chapter', error);
    }
    
    dispatch(setChapterUpdated(true));
  };
};

export const reorderChapters = (projectId, sourceIndex, destinationIndex) => {
  return async (dispatch, getState) => {
    const token = await getAuthToken();
    const chapters = getState().project.chapters;

    // Creating a copy of the chapters array for manipulation
    let reorderedChapters = [...chapters];

    // Swapping chapters in the array
    [reorderedChapters[sourceIndex], reorderedChapters[destinationIndex]] = 
    [reorderedChapters[destinationIndex], reorderedChapters[sourceIndex]];

    try {
      // Update all chapters in the reordered array
      for (const [index, chapter] of reorderedChapters.entries()) {
        // Update chapter number based on the new index
        const updatedChapter = { ...chapter, chapter_number: index + 1 };

        let response = await fetch(`${API_CHAPTERS_URL}/project/${projectId}/${chapter['item_type#item_id'].split('#')[1]}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(updatedChapter)
        });

        if (!response.ok) throw new Error(`Error updating chapter ${chapter.chapter_number} during reorder`);
      }

      // Dispatch the reorder success action with the reordered chapters
      dispatch(fetchEntitySuccess(REORDER_CHAPTERS_SUCCESS, { chapters: reorderedChapters }));

    } catch (error) {
      console.error('Error reordering chapters', error);
      dispatch(fetchEntityFailure(FETCH_CHAPTERS_FAILURE, error));
    }

    dispatch(setChapterUpdated(true));
  };
};


// Fetching data Thunk Action Creators
export const fetchChapters = (projectId) => {
  return async (dispatch) => {
    dispatch(fetchEntityBegin(FETCH_CHAPTERS_BEGIN));
    try {
      const token = await getAuthToken();
      const response = await fetch(`${API_CHAPTERS_URL}/project/${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) throw new Error('Error fetching chapters');

      const chapters = await response.json();
      dispatch(fetchEntitySuccess(FETCH_CHAPTERS_SUCCESS, { chapters }));
    } catch (error) {
      dispatch(fetchEntityFailure(FETCH_CHAPTERS_FAILURE, error));
    }
  };
};

export const fetchCharacters = (projectId) => {
    return async (dispatch) => {
        dispatch(fetchEntityBegin(FETCH_CHARACTERS_BEGIN));
        try {
            const token = await getAuthToken();
            const response = await fetch(`${API_CHARACTERS_URL}/project/${projectId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (!response.ok) throw new Error('Error fetching characters');

            const characters = await response.json();
            dispatch(fetchEntitySuccess(FETCH_CHARACTERS_SUCCESS, { characters }));
            
        } catch (error) {
            dispatch(fetchEntityFailure(FETCH_CHARACTERS_FAILURE, error));
        }
    };
};

export const fetchPlots = (projectId) => {
  return async (dispatch) => {
    dispatch(fetchEntityBegin(FETCH_PLOTS_BEGIN));
    try {
      const token = await getAuthToken();
      const response = await fetch(`${API_PLOTS_URL}/project/${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) throw new Error('Error fetching plots');

      const plots = await response.json();
      dispatch(fetchEntitySuccess(FETCH_PLOTS_SUCCESS, { plots }));
    } catch (error) {
      dispatch(fetchEntityFailure(FETCH_PLOTS_FAILURE, error));
    }
  };
};

export const fetchProjects = () => {
  return async (dispatch) => {
    dispatch(fetchEntityBegin(FETCH_PROJECTS_BEGIN));
    try {
      const token = await getAuthToken();
      const response = await fetch(`${API_PROJECTS_URL}/my/`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) throw new Error('Error fetching projects');

      const projects = await response.json();
      dispatch(fetchEntitySuccess(FETCH_PROJECTS_SUCCESS, { projects }));
    } catch (error) {
      dispatch(fetchEntityFailure(FETCH_PROJECTS_FAILURE, error));
    }
  };
};

export const fetchChatHistoryBegin = () => ({
  type: FETCH_CHAT_HISTORY_BEGIN,
});

export const fetchChatHistorySuccess = (messages) => ({
  type: FETCH_CHAT_HISTORY_SUCCESS,
  payload: { messages },
});

export const fetchChatHistoryFailure = (error) => ({
  type: FETCH_CHAT_HISTORY_FAILURE,
  payload: { error },
});

export const sendMessageSuccess = (message) => ({
  type: SEND_MESSAGE_SUCCESS,
  payload: { message },
});

export const fetchChatHistory = (projectId) => async (dispatch) => {
  dispatch(fetchChatHistoryBegin());
  try {
    const token = await getAuthToken();
    // Replace with your API endpoint
    const response = await fetch(`https://08l6006ubd.execute-api.us-west-2.amazonaws.com/v1/chat_messages/project/${projectId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.ok) {
      const messages = await response.json();
      console.log("API Response: ", messages); // Log the API response

      dispatch(fetchChatHistorySuccess(messages));
    } else {
      throw new Error('Error fetching chat history');
    }
  } catch (error) {
    dispatch(fetchChatHistoryFailure(error));
  }
};

export const sendMessage = (projectId, userMessage, system_msg, project) => async (dispatch, getState) => {
  console.log(project)
  if(project !== undefined && project) {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const { chapters, characters, plots, messages} = getState().project;
      const message_id = generateUuid();
      const timestamp = new Date().toISOString(); 
      const chat_history = messages.messages === undefined ? [] : messages.messages;
      
      console.log(project)

      const requestBody = {
        message_id: message_id,
        project_id: projectId,
        timestamp: timestamp,
        system_msg: system_msg, 
        user_msg: userMessage,
        chatHistory: chat_history,
        projectData: project,
        activeChapter: chapters.length > 0 ? chapters[0] : null,
        chapterList: chapters,
        characterList: characters,
        plotList: plots
      };

      console.log("Request Body: ", requestBody); // Log the request body
      // Send the message to the API
      const response = await fetch('https://08l6006ubd.execute-api.us-west-2.amazonaws.com/v1/send_message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        const responseData = await response.json();

        
        const userMessageObj = {
          text: userMessage,
          type: 'user',
          timestamp: new Date().toISOString()
        };

        const fableFlowMessageObj = {
          text: responseData,
          type: 'FableFlow',
          timestamp: new Date().toISOString()
        };

        return fableFlowMessageObj; 
      } else {
        throw new Error('Error sending message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }
};

export const setSelectedVoice = (voiceId) => ({
  type: SET_SELECTED_VOICE,
  payload: { voiceId },
});

export const toggleMute = () => ({
  type: TOGGLE_MUTE,
});

export const logout = () => {
  return { type: 'LOGOUT' };
};

export const updateProject = (projectId, projectData) => {
  return async (dispatch) => {
      const token = await getAuthToken();
      try {
          const response = await fetch(`https://bhg6huunyl.execute-api.us-west-2.amazonaws.com/v1/projects/${projectId}`, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(projectData),
          });

          if (response.ok) {
              console.log('Project details saved successfully');
              // After a successful update, fetch the updated projects list
              dispatch(fetchProjects());
          } else {
              console.error('Error saving project details', response.status);
              // Handle error
          }
      } catch (error) {
          console.error('Error saving project details', error);
          // Handle error
      }
  };
  
};

export const createCharacter = (projectId, characterData) => async (dispatch) => {
  dispatch({ type: CREATE_CHARACTER_REQUEST });

  try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const response = await fetch(`[API_ENDPOINT]/characters/project/${projectId}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(characterData)
      });

      if (response.ok) {
          const createdCharacter = await response.json();
          dispatch({ type: CREATE_CHARACTER_SUCCESS, payload: createdCharacter });
      } else {
          throw new Error('Error creating character');
      }
  } catch (error) {
      dispatch({ type: CREATE_CHARACTER_FAILURE, error: error.message });
  }
};

export const createProject = (projectData) => async (dispatch) => {
    dispatch({ type: CREATE_PROJECT_REQUEST });

    try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const response = await fetch(`${API_PROJECTS_URL}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(projectData),
        });

        if (response.ok) {
            const createdProject = await response.json();
            dispatch({ type: CREATE_PROJECT_SUCCESS, payload: createdProject });
            return createdProject.project_id;
        } else {
            throw new Error('Error creating project');
        }
    } catch (error) {
        dispatch({ type: CREATE_PROJECT_FAILURE, error: error.message });
    }
};
