import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import projectReducer from './projectReducer'; 

// Configure the Redux store
const store = configureStore({
  reducer: {
    user: userReducer,
    project: projectReducer,
  },
});

export { store };
