import { createSlice } from '@reduxjs/toolkit';

const projectSlice = createSlice({
  name: 'project',
  initialState: {
    projectDetails: null,
    projects: [], 
    characters: {}, // Added characters object to manage character details by ID
  },
  reducers: {
    setProjectDetails: (state, action) => {
      state.projectDetails = action.payload;
    },
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    saveCharacter: (state, action) => { // New reducer case for saving character data
      const { characterId, character } = action.payload;
      state.characters[characterId] = character;
    },
  },
});

// Export the actions
export const { setProjectDetails, setProjects, saveCharacter } = projectSlice.actions;

// Selector functions
export const selectProjectDetails = (state) => state.project.projectDetails;
export const selectProjects = (state) => state.project.projects;
export const selectCharacter = (state, characterId) => state.project.characters[characterId]; // Selector for individual character data

export default projectSlice.reducer;
