import React from 'react';

const ChapterDetailsForm = ({ chapterData, handleInputChange }) => {
    return (
        <div>
            <div className="input-container">
                <input
                    id="title"
                    name="title"
                    value={chapterData.title || ''}
                    onChange={handleInputChange}
                    placeholder=" "
                    className={chapterData.title ? 'filled' : ''}
                />
                <label htmlFor="title" className="floating-label">Title</label>
            </div>
            <div className="input-container">
                <textarea
                    id="summary"
                    name="summary"
                    value={chapterData.summary || ''}
                    onChange={handleInputChange}
                    placeholder=" "
                    className={chapterData.summary ? 'filled' : ''}
                />
                <label htmlFor="summary" className="floating-label">Summary</label>
            </div>
            <div className="input-container">
                <input
                    id="setting"
                    name="setting"
                    value={chapterData.setting || ''}
                    onChange={handleInputChange}
                    placeholder=" "
                    className={chapterData.setting ? 'filled' : ''}
                />
                <label htmlFor="setting" className="floating-label">Setting</label>
            </div>
            <div className="input-container">
                <input
                    id="conflict"
                    name="conflict"
                    value={chapterData.conflict || ''}
                    onChange={handleInputChange}
                    placeholder=" "
                    className={chapterData.conflict ? 'filled' : ''}
                />
                <label htmlFor="conflict" className="floating-label">Conflict</label>
            </div>
            <div className="input-container">
                <input
                    id="resolution"
                    name="resolution"
                    value={chapterData.resolution || ''}
                    onChange={handleInputChange}
                    placeholder=" "
                    className={chapterData.resolution ? 'filled' : ''}
                />
                <label htmlFor="resolution" className="floating-label">Resolution</label>
            </div>
        </div>
    );
};

export default ChapterDetailsForm;
