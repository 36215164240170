import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ChapterList.css';
import AIChatWindow from './AIChatWindow';
import Navbar from './Navbar';
import ChapterCard from './ChapterCard';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  fetchChapters,
  fetchCharacters,
  fetchPlots,
  addChapter,
  updateChapter,
  deleteChapter,
  reorderChapters,
  fetchProjects
} from './projectActions';

const ChapterList = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const [isAddingNewChapter, setIsAddingNewChapter] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [selectedVoice, setSelectedVoice] = useState('Matthew');
    const [activeChapter, setActiveChapter] = useState(null);
    const chapters = useSelector(state => state.project.chapters || [], shallowEqual);
    const projects = useSelector(state => state.projects || [], shallowEqual);
    const characters = useSelector(state => state.project.characters);
    const plots = useSelector(state => state.plots || [], shallowEqual);
    const isLoading = useSelector(state => state.loading || false, shallowEqual);
    const nextChapterId = chapters.reduce((maxId, chapter) => Math.max(maxId, chapter.chapter_number), 0) + 1;
    const handleChapterSelect = (selectedChapter) => {
      setActiveChapter(selectedChapter);
    };
    const chapterUpdated = useSelector(state => state.project.chapterUpdated);

    useEffect(() => {
      if (projectId && chapterUpdated) {
          dispatch(fetchChapters(projectId));
          // Optionally reset the chapterUpdated flag here
      }
  }, [dispatch, projectId, chapterUpdated]);

    useEffect(() => {
        if (projectId) {
            dispatch(fetchChapters(projectId));
            dispatch(fetchCharacters(projectId));
            dispatch(fetchPlots(projectId));
            dispatch(fetchProjects());
        }
    }, [dispatch, projectId]);

    const toggleMute = () => setIsMuted(prevMuted => !prevMuted);


    return (
        <div>
            <Navbar isMuted={isMuted} toggleMute={toggleMute} selectedVoice={selectedVoice} setSelectedVoice={setSelectedVoice} projects={projects} currentProjectId={projectId} />
            <div className='chapter-list'>
                {isLoading && <div className="loading">Loading...</div>}

                {!isLoading && chapters.map((chapter, index) => {
                    return (
                        <ChapterCard
                            key={`${chapter.project_id}-${chapter['item_type#item_id']}`}
                            chapter={chapter}
                            characters={characters}
                            plots={plots}
                            projectId={projectId}
                            index={index}
                            chaptersLength={chapters.length}
                        />
                    );
                })}
                {isAddingNewChapter && (
                    // Log the key for the new chapter card
                    console.log(`Rendering new chapter with key: new-chapter-${nextChapterId}`),
                    <ChapterCard
                        key={`new-chapter-${nextChapterId}`}
                        chapter={{ chapter_number: nextChapterId }}
                        characters={characters}
                        plots={plots}
                        updateChapter={updateChapter}
                        deleteChapter={deleteChapter}
                        addChapter={addChapter}
                        onSelect={handleChapterSelect}
                        chapters={chapters}
                        nextChapterId={nextChapterId}
                        projectId={projectId}
                        dispatch={dispatch}
                        index={chapters.length}
                        chaptersLength={chapters.length}
                    />
                )}

                <button className='add-chapter-button' onClick={() => setIsAddingNewChapter(true)}>Add Chapter</button>
                
            </div>
            <AIChatWindow isMuted={isMuted} /* other props */ />
        </div>
    );
};

export default ChapterList;
