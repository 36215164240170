import React from 'react';

const PlotSelection = ({ plots, handlePlotSelect }) => {
    return (
        <div>
            {plots.map(plot => (
                <button key={plot.id} onClick={() => handlePlotSelect(plot.id)}>
                    {plot.title}
                </button>
            ))}
        </div>
    );
};

export default PlotSelection;
