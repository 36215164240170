import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = JSON.stringify(action.payload); // Serialize the user object to JSON
    },
    // Add any other reducers as needed
  },
});

export const { setUser } = userSlice.actions;

export const selectUser = (state) => {
  return state.user.user ? JSON.parse(state.user.user) : null;
};

export default userSlice.reducer;
