import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';

Amplify.configure(config);

const root = document.getElementById('root');
const reactRoot = createRoot(root);


reactRoot.render(
  
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
