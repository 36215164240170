import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

const ThemeEditor = ({ themes, handleThemeChange, addTheme, removeTheme }) => {
    const [editingIndex, setEditingIndex] = useState(null);
    const [tempTheme, setTempTheme] = useState('');
    const [newTheme, setNewTheme] = useState('');

    const handleChange = (e, index) => {
        if (index === editingIndex) {
            setTempTheme(e.target.value);
        } else {
            handleThemeChange(e.target.value, index);
        }
    };

    const startEditing = (index, theme) => {
        setEditingIndex(index);
        setTempTheme(theme);
    };

    const saveEditedTheme = () => {
        if (!tempTheme.trim() || themes.includes(tempTheme)) {
            // Do not show toast here. Handle this in ChapterCard.
            return;
        }
        handleThemeChange(tempTheme, editingIndex);
        setEditingIndex(null);
    };

    const addNewTheme = () => {
        if (!newTheme.trim() || themes.includes(newTheme)) {
            // Do not show toast here. Handle this in ChapterCard.
            return;
        }
        addTheme(newTheme);
        setNewTheme('');
    };

    return (
        <div>
            {themes.map((theme, index) => (
                <div key={index} className="theme-tag">
                    {editingIndex === index ? (
                        <input
                            type="text"
                            value={tempTheme}
                            onChange={e => handleChange(e, index)}
                            onBlur={saveEditedTheme}
                        />
                    ) : (
                        <>
                            <span onClick={() => startEditing(index, theme)}>{theme}</span>
                            <button className='delete-theme-button' onClick={() => removeTheme(index)}>x</button>
                        </>
                    )}
                </div>
            ))}
            <input 
                type="text" 
                value={newTheme} 
                onChange={(e) => setNewTheme(e.target.value)} 
                placeholder="Add new theme" 
            />
            <button onClick={addNewTheme} className="add-theme-icon">+</button>
        </div>
    );
};

export default ThemeEditor;
