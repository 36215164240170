import React from 'react';
import './ChapterControlButtons.css';

const ChapterControlButtons = ({ saveChapter, removeChapter, moveChapterUp, moveChapterDown }) => {
    return (
        <div>
            <button onClick={saveChapter} className="chapter-control-button chapter-save-button" title="Save chapter">
                Save
            </button>
            <button onClick={removeChapter} className="chapter-control-button delete-button" title="Delete chapter">
                Delete
            </button>
            <button onClick={moveChapterUp} className="chapter-control-button move-up-button" title="Move chapter up">
                Up
            </button>
            <button onClick={moveChapterDown} className="chapter-control-button move-down-button" title="Move chapter down">
                Down
            </button>
        </div>
    );
};

export default ChapterControlButtons;
