import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <header className="landing-header">
        <h1>Bring Your Writing Ideas to Life!</h1>
        <p>Collaborate with AI and unleash your creativity.</p>
      </header>
      <main className="landing-main">
        <section className="cta-section">
          <Link to="/app">
            <button className="cta-button">Start Writing Now!</button>
          </Link>
        </section>
        <section className="features-section">
          <p>✓ Work with AI Chatbots</p>
          <p>✓ Manage Projects and Characters</p>
          <p>✓ Develop Intriguing Plot Points</p>
        </section>
      </main>
      <footer className="landing-footer">
      </footer>
    </div>
  );
};

export default LandingPage;
