import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createProject, updateProject, fetchProjects } from './projectActions';
import { TextField, Button, Container, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import AIChatWindow from './AIChatWindow';
import Navbar from './Navbar';

const ProjectCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState(null); // Added state for projectId
  const [projectData, setProjectData] = useState({ title: '', genre: '', summary: '', word_count_target: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [initialProjectCreated, setInitialProjectCreated] = useState(false);
  const initialProjectCreationRef = useRef(false);
  const placeholderData = { title: 'Untitled Project', genre: 'Unknown', summary: 'No summary', word_count_target: 10000 };
  const projects = useSelector(state => state.project.projects);
  
  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  useEffect(() => {
    const createInitialProject = async () => {
      
      setInitialProjectCreated(true);
      console.log(initialProjectCreationRef.current)
      
      console.log(initialProjectCreationRef.initialProjectCreated)
      if (!initialProjectCreationRef.current && initialProjectCreated) {
        
        setIsLoading(true);
        try {
          
          const newProjectId = await dispatch(createProject(placeholderData));
          setProjectId(newProjectId);
          initialProjectCreationRef.current = true;
        } catch (error) {
          console.error('Error creating initial project:', error);
          setSnackbarMessage('Error creating initial project. Please try again.');
          setSnackbarOpen(true);
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    createInitialProject();
  }, [dispatch, projectId, initialProjectCreated]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (projectId) {
      setIsLoading(true); // Set loading state to true while updating
      try {
        // Ensure that project_id is included in the update data
        const updateData = { ...projectData, project_id: projectId };
  
        await dispatch(updateProject(projectId, updateData));
        setIsLoading(false); // Reset loading state
        navigate(`/project/${projectId}`); // Redirect only after successful update
      } catch (error) {
        console.error('Error updating project:', error);
        setSnackbarMessage('Error updating project. Please try again.');
        setSnackbarOpen(true);
        setIsLoading(false); // Reset loading state even if there's an error
      }
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (isLoading) {
    return (
      <Container>
        <CircularProgress />
        <Typography variant="h6">Creating your project...</Typography>
      </Container>
    );
  }

  return (
    <>
      <Navbar />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      
      <Container maxWidth="sm">
        <Typography variant="h4" sx={{ my: 4 }}>Create Project</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Title"
            name="title"
            value={projectData.title}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Genre"
            name="genre"
            value={projectData.genre}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Summary"
            name="summary"
            value={projectData.summary}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            multiline
          />
          <TextField
            label="Word Count Target"
            name="word_count_target"
            value={projectData.word_count_target}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            type="number"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
          >
            Save
          </Button>
        </form>
      </Container>
      <AIChatWindow projectId={projectId} projectData={projectData} isNewProject={true} />


    </>
  );
};

export default ProjectCreation;