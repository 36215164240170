import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateChapter, deleteChapter, addChapter, reorderChapters } from './projectActions';
import CharacterSelection from './CharacterSelection';
import PlotSelection from './PlotSelection';
import ThemeEditor from './ThemeEditor'; // Updated ThemeEditor
import ChapterDetailsForm from './ChapterDetailsForm';
import ChapterControlButtons from './ChapterControlButtons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ChapterCard.css';

const ChapterCard = ({ chapter, characters, plots, projectId, index, chaptersLength }) => {
    const [chapterData, setChapterData] = useState({
        ...chapter,
        main_characters: chapter.main_characters || [],
        related_plots: chapter.related_plots || [],
        themes: chapter.themes || []
    });
    const [characterSearch, setCharacterSearch] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const dispatch = useDispatch();

    // Filter characters based on search input
    const filteredCharacters = characters.filter(character =>
        character.name.toLowerCase().includes(characterSearch.toLowerCase()) &&
        !chapterData.main_characters.includes(character['item_type#item_id'])
    );
    
    const selectedCharacters = chapterData.main_characters
    .map(characterCompositeId => characters.find(character => character['item_type#item_id'] === characterCompositeId))
    .filter(character => character !== undefined);

    const handleAddTheme = (newTheme) => {
        console.log("handleAddTheme called"); // Debugging
        if (!newTheme.trim()) {
            toast.error('Theme cannot be empty.');
            return;
        }
        if (chapterData.themes.includes(newTheme)) {
            toast.error('Duplicate theme.');
            return;
        }
        setChapterData({ 
            ...chapterData, 
            themes: [...chapterData.themes, newTheme] 
        });
        toast.success('Theme added successfully!');
    };

    const handleSaveChapter = () => {
        console.log("handleSaveChapter called"); // Debugging

        if (!chapterData['item_type#item_id']) {
            dispatch(addChapter(projectId, chapterData));
            toast.success('Chapter added successfully!', { toastId: "chapter-save" });
        } else {
            dispatch(updateChapter(projectId, chapterData));
            toast.success('Chapter added successfully!', { toastId: "chapter-save" });
        }
    };

    const handleDeleteChapter = () => {
        dispatch(deleteChapter(projectId, chapterData['item_type#item_id']));
        toast.success('Chapter deleted successfully!', { toastId: "chapter-delete" });
    };

    const handleMoveChapter = (direction) => {
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        dispatch(reorderChapters(projectId, index, newIndex));
        toast.success(`Chapter moved ${direction}`, { toastId: "chapter-move" });
    };

    return (
        
        <div className="chapter-card">
                        <ToastContainer />

            <div className="chapter-header">
            <h3>Chapter {chapter.chapter_number}: {chapter.title}</h3>
                <ChapterControlButtons
                    saveChapter={handleSaveChapter}
                    removeChapter={handleDeleteChapter}
                    moveChapterUp={index > 0 && index !== chaptersLength - 1 ? () => handleMoveChapter('up') : null}
                    moveChapterDown={index < chaptersLength - 1 ? () => handleMoveChapter('down') : null}
                />
            </div>

            {isExpanded && (
                <div className="chapter-details">
                    <h4>Chapter Details</h4>
                    <ChapterDetailsForm
                        chapterData={chapterData}
                        handleInputChange={e => {
                            const { name, value } = e.target;
                            setChapterData({ ...chapterData, [name]: value });
                        }}
                    />

                    <h4>Character Selection</h4>
                    <div className="character-selection-group">
                        <CharacterSelection
                            characterSearch={characterSearch}
                            handleCharacterSearchChange={e => setCharacterSearch(e.target.value)}
                            filteredCharacters={filteredCharacters}
                            handleCharacterSelect={characterCompositeId => {
                                setChapterData({
                                    ...chapterData,
                                    main_characters: [...chapterData.main_characters, characterCompositeId]
                                });
                            }}
                            selectedCharacters={selectedCharacters}
                            handleCharacterRemove={characterCompositeId => {
                                setChapterData({
                                    ...chapterData,
                                    main_characters: chapterData.main_characters.filter(id => id !== characterCompositeId)
                                });
                            }}
                        />
                    </div>

                    <div className="plot-selection-group">
                        <PlotSelection
                            plots={plots}
                            handlePlotSelect={plotId => {
                                setChapterData({
                                    ...chapterData,
                                    related_plots: [...chapterData.related_plots, plotId]
                                });
                            }}
                        />
                    </div>

                    <h4>Theme Management</h4>
                    <div className="theme-editor-group">
                        <ThemeEditor
                            themes={chapterData.themes}
                            handleThemeChange={(newValue, index) => {
                                const newThemes = [...chapterData.themes];
                                newThemes[index] = newValue;
                                setChapterData({ ...chapterData, themes: newThemes });
                            }}
                            addTheme={handleAddTheme}
                            removeTheme={(index) => {
                                const newThemes = chapterData.themes.filter((_, i) => i !== index);
                                setChapterData({ ...chapterData, themes: newThemes });
                                toast.success('Theme removed successfully!');
                            }}
                        />
                    </div>
                </div>
            )}
            <button className="expand-collapse-btn" onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? 'Collapse' : 'Expand'}
            </button>
        </div>
    );
};

export default ChapterCard;
