import React from 'react';
import './CharacterSelection.css';

const CharacterSelection = ({ characterSearch, handleCharacterSearchChange, filteredCharacters, handleCharacterSelect, selectedCharacters, handleCharacterRemove }) => {
    return (
        <div className="character-selection">
            <div className="character-search">
                <input
                    type="text"
                    value={characterSearch}
                    onChange={handleCharacterSearchChange}
                    placeholder="Search characters"
                />
                {characterSearch && (
                    <button onClick={() => handleCharacterSearchChange({ target: { value: '' }})}>Clear</button>
                )}
            </div>
            
            <div className="character-list-container">
                {filteredCharacters.length > 0 ? (
                    <ul className="character-list">
                        {filteredCharacters.map(character => (
                            <li key={character['item_type#item_id']} onClick={() => handleCharacterSelect(character['item_type#item_id'])}>
                                {character.name}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No characters found</p>
                )}
            </div>

            <div className="selected-characters">
                <p>Selected Characters:</p>
                {selectedCharacters.map(character => (
                    <div className="selected-character" key={character['item_type#item_id']}>
                        {character.name}
                        <button onClick={() => handleCharacterRemove(character['item_type#item_id'])}>Remove</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CharacterSelection;
