import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import Navbar from './Navbar.js';
import './CharacterDetails.css';
import { saveCharacter } from './projectSlice.js';
import { selectUser, setUser } from './userSlice';
import { setProjects, selectProjects } from './projectSlice';
import AIChatWindow from './AIChatWindow.js';

const adjustHeight = (element) => {
  element.style.height = 'auto';
  element.style.height = element.scrollHeight + 'px';
};

const adjustAllTextareas = () => {
  const textareas = document.querySelectorAll('.character-details textarea');
  textareas.forEach((textarea) => adjustHeight(textarea));
};

const CharacterDetails = () => {
  const { characterId, projectId } = useParams();
  const [isMuted, setIsMuted] = useState(false);
  const projects = useSelector(selectProjects);
  const [projectData, setProjectData] = useState({});
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState('Matthew');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [characterData, setCharacterData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [currentTrait, setCurrentTrait] = useState('');

  // Check if projects are already in Redux store, and fetch them if not
  useEffect(() => {
    if (!projects.length) {
      const fetchProjects = async () => {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        try {
          const response = await fetch(
            'https://bhg6huunyl.execute-api.us-west-2.amazonaws.com/v1/projects/my/',
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.ok) {
            const data = await response.json();
            dispatch(setProjects(data));
          } else {
            console.error('Error fetching projects', response.status);
          }
        } catch (error) {
          console.error('Error fetching projects', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchProjects();
    }
  }, [dispatch, projects]);

  const projectDetails = projects.find((proj) => proj.project_id === projectId);

  useEffect(() => {
    if (projectDetails) {
      setProjectData(projectDetails);
    }
  }, [projectDetails]);

  const toggleMute = () => {
    setIsMuted((prevMuted) => !prevMuted);
  };

  const addTrait = () => {
    if (currentTrait.trim()) {
      setCharacterData((prev) => ({
        ...prev,
        traits: [...prev.traits, currentTrait],
      }));
      setCurrentTrait('');
    }
  };

  const removeTrait = (index) => {
    const newTraits = [...characterData.traits];
    newTraits.splice(index, 1);
    setCharacterData({ ...characterData, traits: newTraits });
  };

  useEffect(() => {
    adjustAllTextareas();
  }, [characterData]);

  const handleSave = async () => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    try {
      const response = await fetch(
        `https://kto6bliwo3.execute-api.us-west-2.amazonaws.com/v1/characters/project/${projectId}/${characterId}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(characterData),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to save character details');
      }

      const data = await response.json();

      if (data && data.message === 'Character updated successfully') {
        console.log('Character details saved successfully');

        dispatch(saveCharacter(characterData));
      } else {
        throw new Error(data.message || 'Failed to save character details');
      }
    } catch (error) {
      console.error('There was a problem with the save operation:', error);
    }
  };

  useEffect(() => {
    const fetchCharacterDetails = async () => {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      try {
        const response = await fetch(
          `https://kto6bliwo3.execute-api.us-west-2.amazonaws.com/v1/characters/project/${projectId}/${characterId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCharacterData(data);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCharacterDetails();
  }, [characterId, projectId]);

  useEffect(() => {
    if (characterData) {
      adjustAllTextareas();
    }
  }, [characterData]);

  if (!projectDetails) {
    return <div>Loading project details...</div>;
  }

  return (
    <div className="character-details-container">
      <Navbar isMuted={isMuted} toggleMute={toggleMute} selectedVoice={selectedVoice} setSelectedVoice={setSelectedVoice} projects={projects} currentProjectId={projectId} />
      <h2>Character Details</h2>
      {isLoading ? (
        <div className="loading">
          <span className="spinner"></span>
          Loading...
        </div>
      ) : characterData ? (
        <div>
          <div className="character-details">
            <label>
              Name:
              <input type="text" value={characterData.name} onChange={(e) => setCharacterData({ ...characterData, name: e.target.value })} />
            </label>
            <label>
              Role:
              <textarea value={characterData.role} onChange={(e) => { setCharacterData({ ...characterData, role: e.target.value }); adjustHeight(e.target); }}></textarea>
            </label>
            <label>
              Age:
              <input type="number" value={characterData.age} onChange={(e) => setCharacterData({ ...characterData, age: e.target.value })} />
            </label>
            <label>
              Background:
              <textarea value={characterData.background} onChange={(e) => { setCharacterData({ ...characterData, background: e.target.value }); adjustHeight(e.target); }}></textarea>
            </label>
            <label>
              Personality:
              <textarea value={characterData.personality} onChange={(e) => { setCharacterData({ ...characterData, personality: e.target.value }); adjustHeight(e.target); }}></textarea>
            </label>
            <label>
              Physical Appearance:
              <textarea value={characterData.appearance} onChange={(e) => { setCharacterData({ ...characterData, appearance: e.target.value }); adjustHeight(e.target); }}></textarea>
            </label>
            <label>
              Strengths:
              <textarea value={characterData.strengths} onChange={(e) => { setCharacterData({ ...characterData, strengths: e.target.value }); adjustHeight(e.target); }}></textarea>
            </label>
            <label>
              Weaknesses:
              <textarea value={characterData.weaknesses} onChange={(e) => { setCharacterData({ ...characterData, weaknesses: e.target.value }); adjustHeight(e.target); }}></textarea>
            </label>
            <label>
              Relationships:
              <textarea value={characterData.relationships} onChange={(e) => { setCharacterData({ ...characterData, relationships: e.target.value }); adjustHeight(e.target); }}></textarea>
            </label>
            <label>
              Other Notes:
              <textarea value={characterData.notes} onChange={(e) => { setCharacterData({ ...characterData, notes: e.target.value }); adjustHeight(e.target); }}></textarea>
            </label>
            <label>
              Traits:
              <input
                type="text"
                placeholder="Enter a trait and press Add"
                value={currentTrait}
                onChange={(e) => setCurrentTrait(e.target.value)}
              />
              <button type="button" onClick={addTrait}>Add Trait</button>
              <ul className='trait-list'>
                {characterData.traits && characterData.traits.map((trait, index) => (
                  <li key={index}>
                    <button className='trait-button' type="button" onClick={() => removeTrait(index)}>-</button>
                    {trait}
                  </li>
                ))}
              </ul>
            </label>
          </div>
          <button className="save-button" onClick={handleSave} title="Save the character details">Save <i className="save-icon"></i></button>
        </div>
      ) : (
        <p>Character not found</p>
      )}
      <AIChatWindow isMuted={isMuted} projectData={projectDetails} messages={chatHistory} setChatHistory={setChatHistory} selectedVoice={selectedVoice} />
    </div>
);

}

export default CharacterDetails;