import React, { useState, useEffect, useRef, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { fetchChatHistory, sendMessage, toggleChatWindow } from './projectActions';
import { Button, TextField, Card, Typography, IconButton, CircularProgress, Snackbar, Alert, Divider } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';

const Message = memo(({ message }) => {
  const messageText = typeof message.text === 'string' ? message.text : message.text.message;

  return (
    <Card variant="outlined" sx={{ mb: 2, p: 2, bgcolor: message.type === 'user' ? '#d9edf7' : '#dff0d8' }}>
      <Typography variant="body2" color="textSecondary">
        {formatDate(message.timestamp)}:
      </Typography>
      <Typography variant="body1">{messageText}</Typography>
    </Card>
  );
});

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${month}/${day}/${year}, ${hours}:${minutes}:${seconds}`;
};

const AIChatWindow = ({ projectData, isNewProject = false, projectId }) => {
  const [userInput, setUserInput] = useState('');
  const [localMessages, setLocalMessages] = useState([]);
  const currentAudioRef = useRef(null);
  const dispatch = useDispatch();
  const { selectedVoice, isMuted } = useSelector(state => state.project);
  const reduxMessages = useSelector(state => state.project.messages.messages || []);
  const chatHistoryRef = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const isAIChatCollapsed = useSelector(state => state.project.isAIChatCollapsed);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [initialMessageSent, setInitialMessageSent] = useState(false);
  const initialMessageSentRef = useRef(false);
  const fetchHistory = async () => {
    console.log('fetchHistory called');

    setIsLoadingHistory(true);
      try {
        if(projectId !== undefined) {
          await dispatch(fetchChatHistory(projectId));
        } else {
          await dispatch(fetchChatHistory(projectData['project_id']));
        }
        
      } catch (error) {
        console.error('Error fetching chat history:', error);
        setSnackbarMessage('Error fetching chat history. Please try again.');
        setOpenSnackbar(true);
      } finally {
        setIsLoadingHistory(false);
      }
    };

  useEffect(() => {
    console.log(projectData)
    if(projectData['project_id'] !== undefined) {
      fetchHistory();
    }

  }, [projectData, dispatch]);

  useEffect(() => {
    
    const sendInitialMessage = async () => {
      if (!initialMessageSentRef.current && isNewProject && projectId) {
        initialMessageSentRef.current = true;

        const initialMessage = "I've just started a new writing project and would like some help getting started.";
        try {
          const initialProjectData = { title: 'Untitled Project', genre: 'Unknown', summary: 'No summary', word_count_target: 10000, project_id: projectId };
          const aiResponse = await dispatch(sendMessage(projectId, initialMessage, "You are a helpful AI assistant called FableFlow.", initialProjectData));
          const aiResponseText = typeof aiResponse.text === 'string' ? aiResponse.text : aiResponse.text.message;
          playMessage(aiResponseText); 
          fetchHistory();
        } catch (error) {
          console.error('Error sending initial message:', error);
        }
      }
    };

    if(initialMessageSentRef.current === false)
    {
      sendInitialMessage();
    }
   
    
  }, [isNewProject, projectId]);
  

  useEffect(() => {
    setLocalMessages(reduxMessages);
  }, [reduxMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [localMessages]);

  const scrollToBottom = () => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  };

  const playMessage = async (message) => {
    if (isMuted) return;
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();

      const response = await fetch(
        'https://l7qozzjm7b.execute-api.us-west-2.amazonaws.com/v1/synthesize_speech',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ text: message, voiceId: selectedVoice }),
        }
      );

      if (response.ok) {
        const base64Audio = await response.text();
        const audioData = Uint8Array.from(atob(base64Audio), (c) => c.charCodeAt(0));
        const blob = new Blob([audioData], { type: 'audio/mpeg' });
        const blobURL = URL.createObjectURL(blob);

        if (currentAudioRef.current) {
          currentAudioRef.current.pause();
          currentAudioRef.current = null;
        }

        const audio = new Audio(blobURL);
        audio.addEventListener('canplaythrough', () => {
          audio.play();
        });

        currentAudioRef.current = audio;
      } else {
        console.error('Error synthesizing speech:', response.status);
      }
    } catch (error) {
      console.error('Error synthesizing speech:', error);
    }
  };
  
  const handleSendMessage = async (message) => {
    if (message.trim() === '') return;
    setIsSending(true);

    try {
      const defaultProjectData = { title: 'placeholder', genre: 'placeholder', summary: 'placeholder', word_count_target: '10000', project_id: projectId };
      
      const updatedProjectData = isNewProject ? defaultProjectData : projectData;
      const aiResponse = await dispatch(sendMessage(projectId, message, "You are a helpful AI assistant called FableFlow.", updatedProjectData));
      const aiResponseText = typeof aiResponse.text === 'string' ? aiResponse.text : aiResponse.text.message;

      setUserInput('');

      if (aiResponse && aiResponseText && aiResponseText.trim() !== '') {
        playMessage(aiResponseText); 
        setLocalMessages([...localMessages, 
          { type: 'user', text: message, timestamp: new Date().getTime() },
          { type: 'FableFlow', text: aiResponseText, timestamp: new Date().getTime() }
        ]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setSnackbarMessage('Error sending message. Please try again.');
      setOpenSnackbar(true);
    }

    setIsSending(false);
  };
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleToggle = () => {
    dispatch(toggleChatWindow());
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSendClick = () => {
    handleSendMessage(userInput);
  };
  
  return (
    <Card sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000, transition: 'max-height 0.3s ease-in-out', maxHeight: isAIChatCollapsed ? '50px' : 'calc(100vh - 100px)', overflow: 'hidden' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#f2f2f2', padding: '8px' }}>
        <Typography variant="subtitle1">Chat</Typography>
        <IconButton onClick={handleToggle} sx={{ marginRight: '-12px' }}>
          {isAIChatCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
      <Divider />

      {!isAIChatCollapsed && (
        <>
          {isLoadingHistory ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </div>
          ) : (
            <div ref={chatHistoryRef} style={{ overflowY: 'auto', maxHeight: 'calc(50vh - 120px)', flexGrow: 1 }}>
              {localMessages.map((message, index) => (
                <Message key={index} message={message} />
              ))}
            </div>
          )}

          <div style={{ display: 'flex', alignItems: 'center', mt: 2, padding: '8px' }}>
            <TextField
              fullWidth
              variant="outlined"
              name="userInput"
              value={userInput}
              placeholder="Type your message..."
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if ((e.code === 'Enter' || e.key === 'Enter') && !isSending) {
                  handleSendClick();
                }
              }}
              disabled={isSending}
              sx={{ marginRight: 2 }} // Adds right margin to the TextField
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendClick}
              disabled={isSending}
              startIcon={isSending ? <CircularProgress size={24} /> : <SendIcon />}
            >
              Send
            </Button>
          </div>
        </>
      )}

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default AIChatWindow;