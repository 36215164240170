import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AppBar, Toolbar, Typography, IconButton, Button, Menu, MenuItem, FormControl, InputLabel, Select, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import { toggleMute, setSelectedVoice, logout as logoutAction } from './projectActions';

const voices = ['Danielle', 'Gregory', 'Ivy', 'Joanna', 'Kendra', 'Kimberly', 'Salli', 'Joey', 'Justin', 'Kevin', 'Matthew', 'Ruth', 'Stephen'];

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const projects = useSelector(state => state.project.projects);
  const selectedVoice = useSelector(state => state.project.selectedVoice);
  const isMuted = useSelector(state => state.project.isMuted);
  const currentProjectId = useSelector(state => state.project.currentProjectId);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      dispatch(logoutAction());
      navigate('/app');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleVoiceChange = (voice) => {
    dispatch(setSelectedVoice(voice));
    handleClose();
  };

  const handleMuteToggle = () => {
    dispatch(toggleMute());
    handleClose();
  };

  const handleProjectChange = (newProjectId) => {
    navigate(`/project/${newProjectId}`);
    handleClose();
  };

  const handleProjectCreate = () => {
    navigate('/create-project');
    handleClose();
  };

  const renderVoiceDropdown = () => (
    <FormControl fullWidth margin="normal">
      <InputLabel id="voice-select-label">AI Voice</InputLabel>
      <Select
        labelId="voice-select-label"
        id="voice-select"
        value={selectedVoice}
        label="AI Voice"
        onChange={(event) => handleVoiceChange(event.target.value)}
      >
        {voices.map(voice => (
          <MenuItem key={voice} value={voice}>{voice}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
  
  const renderProjectDropdown = () => (
    <FormControl fullWidth>
      <InputLabel id="project-select-label">Project</InputLabel>
      <Select
        labelId="project-select-label"
        id="project-select"
        value={currentProjectId}
        label="Project"
        onChange={(event) => handleProjectChange(event.target.value)}
      >
        {projects.map(proj => (
          <MenuItem key={proj.project_id} value={proj.project_id}>{proj.title}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderProjectSpecificMenuItems = () => {
    if (!projectId) return null;

    return (
      <>
        <MenuItem onClick={() => navigate(`/project/${projectId}`)}>Project Details</MenuItem>
        <MenuItem onClick={() => navigate(`/project/${projectId}/characters`)}>Character Development</MenuItem>
        <MenuItem onClick={() => navigate(`/project/${projectId}/chapters`)}>Outline and Chapters</MenuItem>
      </>
    );
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenu}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Fable Flow
        </Typography>
        <Button color="inherit" onClick={handleLogout}>Logout</Button>
      </Toolbar>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {renderProjectSpecificMenuItems()}
        <Divider />
        <MenuItem>
          {renderVoiceDropdown()}
        </MenuItem>
        <MenuItem>
          {renderProjectDropdown()}
        </MenuItem>
        <MenuItem onClick={handleProjectCreate}>
          <IconButton color="inherit">
            <AddIcon />
            Create New Project
          </IconButton>
        </MenuItem>
        <MenuItem onClick={handleMuteToggle}>
          {isMuted ? 'Unmute AI Voice' : 'Mute AI Voice'}
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Navbar;